import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUpDown, faPercent } from "@fortawesome/free-solid-svg-icons";
import { COST_TYPES } from "./constants";

function CostTypeSelector({ onSelect }) {
  return (
    <div className="flex justify-start space-x-4">
      <button
        onClick={() => onSelect(COST_TYPES.FIXED)}
        className="bg-blue-500 text-white px-3 py-1.5 rounded-lg hover:bg-blue-600 transition-colors text-sm"
      >
        Add a Fixed Cost <FontAwesomeIcon className="ml-2" icon={faLock} />
      </button>
      <button
        onClick={() => onSelect(COST_TYPES.VARIABLE)}
        className="bg-blue-500 text-white px-3 py-1.5 rounded-lg hover:bg-blue-600 transition-colors text-sm"
      >
        Add a Variable Cost
        <FontAwesomeIcon className="ml-2" icon={faUpDown} />
      </button>
      <button
        onClick={() => onSelect(COST_TYPES.PERCENTAGE)}
        className="bg-gray-500 text-white px-3 py-1.5 rounded-lg cursor-not-allowed text-sm"
        disabled
      >
        % of Turnover <FontAwesomeIcon className="ml-2" icon={faPercent} />
        <span className="block text-xs">Coming soon</span>
      </button>
    </div>
  );
}

export default CostTypeSelector;
