import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function VatableIncome({ documentId, onBack, onSave }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSave = async () => {
    if (selectedOption === null) return;

    try {
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, {
        "vatSetup.vatableIncome": selectedOption === "yes",
      });
      onSave(selectedOption === "yes");
    } catch (e) {
      console.error("Error updating VAT income setup:", e);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto">
        <button
          onClick={onBack}
          className="mb-6 flex items-center text-white hover:text-gray-300 transition-colors"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          Back
        </button>

        <div className="bg-white rounded-lg p-8 shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            Is all of your income VATable?
          </h2>

          <div className="space-y-4 text-gray-900 mb-6">
            {[
              { id: "yes", label: "Yes" },
              { id: "no", label: "No" },
            ].map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionSelect(option.id)}
                className={`w-full p-4 text-left rounded-lg border-2 transition-all ${
                  selectedOption === option.id
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-200 hover:border-blue-300"
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>

          {selectedOption !== null && (
            <button
              onClick={handleSave}
              className="w-full p-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg flex items-center justify-center transition-colors"
            >
              Next
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default VatableIncome;
