import { useState } from "react";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./firebase";
import Costs from "./components/Costs";
import Income from "./components/Income";
import BankForecast from "./components/BankForecast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "./components/Footer";
import Benefits from "./components/Benefits";
import HowItWorks from "./components/HowItWorks";
import SocialProof from "./components/SocialProof";
import Features from "./components/Features";
import FAQ from "./components/FAQ";
import VatSetup from "./components/VatSetup";
import VatableIncome from "./components/VatableIncome";
import ExpenseTypes from "./components/ExpenseTypes";

function App() {
  const [businessName, setBusinessName] = useState("");
  const [step, setStep] = useState(1);
  const [documentId, setDocumentId] = useState(null);
  const [costs, setCosts] = useState([]);
  const [costCategories, setCostCategories] = useState([]);

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      console.log("Creating project with business name:", businessName);
      const docRef = await addDoc(collection(db, "projects"), {
        businessName: businessName,
        createdAt: serverTimestamp(),
      });
      console.log("Project created successfully with ID:", docRef.id);
      setDocumentId(docRef.id);
      setStep(2);
    } catch (e) {
      console.error("Error adding document:", e);
    }
  };

  const handleSaveVatSetup = async (vatSetup) => {
    if (!documentId) return;
    try {
      console.log("Saving VAT setup for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { vatSetup });
      setStep(3);
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleSaveVatableIncome = async (vatableIncome) => {
    if (!documentId) return;
    try {
      console.log("Saving VATable income setup for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { "vatSetup.vatableIncome": vatableIncome });
      setStep(4);
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleSaveIncome = async (newIncome) => {
    if (!documentId) return;
    try {
      console.log("Saving income for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { income: newIncome });
      setStep(5);
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleExpenseTypeSelect = (template, categories) => {
    if (Array.isArray(template)) {
      setCosts(template);
      setCostCategories(categories);
    }
    setStep(6);
  };

  const handleSaveCosts = async (newCosts) => {
    if (!documentId) return;
    try {
      console.log("Saving costs for document ID:", documentId);
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { costs: newCosts });
      setStep(7);
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <div
      className="min-h-screen flex flex-col text-white"
      style={{ background: "linear-gradient(to bottom, #4B0082, #2E0854)" }}
    >
      {step === 1 && (
        <>
          <div className="flex-grow flex flex-col">
            {/* Logo and Main Heading */}
            <div className="flex flex-col items-center mt-16 px-4 text-center">
              <img
                src="/logo.svg"
                alt="Forecast Logo"
                className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 mb-6"
                style={{
                  animation: "fadeIn 0.8s ease-in",
                }}
              />
              <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
                Small Business Accounting Automation
              </h1>
              <p className="text-base mt-2 md:text-lg lg:text-xl opacity-90">
                Simplify accounting tasks and minimize cost.
              </p>
            </div>

            {/* Input Form */}
            <div className="flex-grow flex items-start mt-20 justify-center px-4">
              <div className="w-full max-w-md bg-gray-50 text-gray-800 p-8 rounded-lg flex flex-col gap-4">
                <label
                  className="text-base md:text-lg lg:text-xl"
                  htmlFor="businessName"
                >
                  Enter your business name to get started.
                </label>
                <form className="flex flex-col gap-4" onSubmit={handleCreate}>
                  <input
                    id="businessName"
                    className="p-3 text-gray-800 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
                    type="text"
                    placeholder="Type your business name here..."
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    className="p-3 font-bold flex gap-3 items-center justify-center bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-base md:text-lg lg:text-xl transition-colors duration-200"
                  >
                    Create a business plan
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </form>
              </div>
            </div>

            {/* New sections */}
            <Benefits />
            <Features />
            <SocialProof />
            <HowItWorks />
            <FAQ />
          </div>
          <Footer />
        </>
      )}
      {step === 2 && (
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <VatSetup
              documentId={documentId}
              onBack={handleBack}
              onSave={handleSaveVatSetup}
            />
          </div>
          <Footer />
        </div>
      )}
      {step === 3 && (
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <VatableIncome
              documentId={documentId}
              onBack={handleBack}
              onSave={handleSaveVatableIncome}
            />
          </div>
          <Footer />
        </div>
      )}
      {step === 4 && (
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <Income
              documentId={documentId}
              onBack={handleBack}
              onSave={handleSaveIncome}
            />
          </div>
          <Footer />
        </div>
      )}
      {step === 5 && (
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <ExpenseTypes
              documentId={documentId}
              onBack={handleBack}
              onSelect={handleExpenseTypeSelect}
            />
          </div>
          <Footer />
        </div>
      )}
      {step === 6 && (
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <Costs
              documentId={documentId}
              onBack={handleBack}
              onSave={handleSaveCosts}
              initialCosts={costs}
              categories={costCategories}
            />
          </div>
          <Footer />
        </div>
      )}
      {step === 7 && (
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <BankForecast documentId={documentId} onBack={handleBack} />
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
