import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { VARIABLE_INPUT_TYPES } from "./constants";

function VariableCostModal({
  showModal,
  variableSetup,
  setVariableSetup,
  onClose,
  onSave,
}) {
  if (!showModal) return null;

  const handleSave = () => {
    if (variableSetup.inputType) {
      onSave(variableSetup.inputType);
      onClose(); // Close the modal after saving
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white text-gray-900 rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">
          Variable Cost Setup
        </h3>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              First Month Amount
            </label>
            <input
              type="number"
              value={variableSetup.firstMonthAmount}
              onChange={(e) =>
                setVariableSetup({
                  ...variableSetup,
                  firstMonthAmount: e.target.value,
                })
              }
              className="w-full p-2 border rounded-md"
              placeholder="Enter amount for first month"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              How should this cost change over time?
            </label>
            <div className="space-y-2">
              <label className="flex items-center space-x-2 p-4 border rounded-md hover:bg-gray-50 cursor-pointer">
                <input
                  type="radio"
                  checked={
                    variableSetup.inputType === VARIABLE_INPUT_TYPES.MANUAL
                  }
                  onChange={() =>
                    setVariableSetup({
                      ...variableSetup,
                      inputType: VARIABLE_INPUT_TYPES.MANUAL,
                      adjustment: "",
                    })
                  }
                />
                <span className="text-sm text-gray-700">
                  Each month will be this amount
                </span>
              </label>

              <div className="p-4 border rounded-md hover:bg-gray-50">
                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="radio"
                    checked={
                      variableSetup.inputType ===
                      VARIABLE_INPUT_TYPES.AMOUNT_INCREASE
                    }
                    onChange={() =>
                      setVariableSetup({
                        ...variableSetup,
                        inputType: VARIABLE_INPUT_TYPES.AMOUNT_INCREASE,
                        adjustment: "",
                      })
                    }
                  />
                  <span className="text-sm text-gray-700">
                    Increase by fixed amount each month
                    <FontAwesomeIcon icon={faArrowUp} className="ml-1" />
                  </span>
                </label>
                {variableSetup.inputType ===
                  VARIABLE_INPUT_TYPES.AMOUNT_INCREASE && (
                  <div className="mt-2 ml-6">
                    <input
                      type="number"
                      value={variableSetup.adjustment}
                      onChange={(e) =>
                        setVariableSetup({
                          ...variableSetup,
                          adjustment: e.target.value,
                        })
                      }
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter amount"
                    />
                  </div>
                )}
              </div>

              <div className="p-4 border rounded-md hover:bg-gray-50">
                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="radio"
                    checked={
                      variableSetup.inputType ===
                      VARIABLE_INPUT_TYPES.PERCENTAGE_INCREASE
                    }
                    onChange={() =>
                      setVariableSetup({
                        ...variableSetup,
                        inputType: VARIABLE_INPUT_TYPES.PERCENTAGE_INCREASE,
                        adjustment: "",
                      })
                    }
                  />
                  <span className="text-sm text-gray-700">
                    Increase by percentage each month
                    <FontAwesomeIcon icon={faPercent} className="ml-1" />
                  </span>
                </label>
                {variableSetup.inputType ===
                  VARIABLE_INPUT_TYPES.PERCENTAGE_INCREASE && (
                  <div className="mt-2 ml-6">
                    <input
                      type="number"
                      value={variableSetup.adjustment}
                      onChange={(e) =>
                        setVariableSetup({
                          ...variableSetup,
                          adjustment: e.target.value,
                        })
                      }
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter percentage"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3 mt-6 pt-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={
              !variableSetup.inputType || !variableSetup.firstMonthAmount
            }
            className="px-4 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default VariableCostModal;
