import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faSave,
  faAdd,
  faGears,
  faInfoCircle,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import BankForecast from "./BankForecast";
import { EXPENSE_LABELS } from "./ExpenseTypes";
import CostsTable from "./costs/CostsTable";
import VariableCostModal from "./costs/VariableCostModal";
import { COST_TYPES, VARIABLE_INPUT_TYPES } from "./costs/constants";

function Costs({
  documentId,
  onBack,
  onSave,
  initialCosts = [],
  categories = [],
}) {
  const [costs, setCosts] = useState(initialCosts);
  const [step, setStep] = useState(1);
  const [excludeVAT, setExcludeVAT] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showSubcategoryColumn, setShowSubcategoryColumn] = useState(false);
  const [costsNeedingType, setCostsNeedingType] = useState(
    initialCosts.map((_, index) => index)
  );
  const [variableSetup, setVariableSetup] = useState({
    rowIndex: null,
    firstMonthAmount: "",
    inputType: null,
    adjustment: "",
  });
  const [showVariableModal, setShowVariableModal] = useState(false);

  const availableCategories =
    categories.length > 0 ? categories : Object.keys(EXPENSE_LABELS);

  const handleAddCost = () => {
    console.log("Adding a new cost row");
    const newCost = {
      supplierName: "",
      VATable: "Yes",
      forecastPaymentDays: 0,
      category: availableCategories[0],
      subcategory: "Overhead",
      paymentTerms: "immediate",
      amounts: Array(12).fill(0),
      type: null,
    };
    setCosts([...costs, newCost]);
    setCostsNeedingType((prev) => [...prev, costs.length]);
  };

  const handleInputChange = (index, field, value) => {
    const newCosts = [...costs];
    newCosts[index][field] = value;
    setCosts(newCosts);
  };

  const handleAmountChange = (index, month, value) => {
    const newCosts = [...costs];
    const cost = newCosts[index];

    if (cost.isFixedMonthly) {
      cost.fixedAmount = value === "" ? "" : parseFloat(value);
      cost.amounts = Array(12).fill(cost.fixedAmount);
    } else {
      cost.amounts[month] = value === "" ? "" : parseFloat(value);
    }

    setCosts(newCosts);
  };

  const handleDeleteCost = (index) => {
    console.log("Deleting cost row at index:", index);
    setCosts(costs.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    if (!documentId) {
      console.error("Document ID is required to save costs.");
      return;
    }
    try {
      console.log("Saving costs for document ID:", documentId);
      await onSave(costs);
      console.log("Costs saved successfully");
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleNext = async () => {
    try {
      await handleSave();
    } catch (e) {
      console.error("Error updating document:", e);
    }
  };

  const handleCopyPreviousMonth = (costIndex, monthIndex) => {
    if (monthIndex === 0) return; // Can't copy if it's the first month

    const newCosts = [...costs];
    newCosts[costIndex].amounts[monthIndex] =
      newCosts[costIndex].amounts[monthIndex - 1];
    setCosts(newCosts);
  };

  const handleCostTypeSelect = (index, type) => {
    const newCosts = [...costs];
    const cost = newCosts[index];

    switch (type) {
      case COST_TYPES.FIXED:
        cost.isFixedMonthly = true;
        cost.fixedAmount = 0;
        cost.amounts = Array(12).fill(0);
        setCosts(newCosts);
        setCostsNeedingType((prev) => prev.filter((i) => i !== index));
        break;
      case COST_TYPES.VARIABLE:
        setVariableSetup({
          rowIndex: index,
          firstMonthAmount: "",
          inputType: null,
          adjustment: "",
        });
        setShowVariableModal(true);
        break;
      case COST_TYPES.PERCENTAGE:
        // Future implementation
        break;
      default:
        console.warn(`Unexpected cost type: ${type}`);
        break;
    }
    cost.type = type;
  };

  const handleVariableSetup = (inputType) => {
    const newCosts = [...costs];
    const cost = newCosts[variableSetup.rowIndex];
    const firstAmount = parseFloat(variableSetup.firstMonthAmount) || 0;

    cost.amounts[0] = firstAmount;

    if (inputType === VARIABLE_INPUT_TYPES.MANUAL) {
      cost.amounts = Array(12).fill(firstAmount);
    } else if (inputType === VARIABLE_INPUT_TYPES.AMOUNT_INCREASE) {
      const adjustment = parseFloat(variableSetup.adjustment) || 0;
      for (let i = 1; i < 12; i++) {
        cost.amounts[i] = cost.amounts[i - 1] + adjustment;
      }
    } else if (inputType === VARIABLE_INPUT_TYPES.PERCENTAGE_INCREASE) {
      const percentage = parseFloat(variableSetup.adjustment) || 0;
      for (let i = 1; i < 12; i++) {
        cost.amounts[i] = cost.amounts[i - 1] * (1 + percentage / 100);
      }
    }

    setCosts(newCosts);
    setCostsNeedingType((prev) =>
      prev.filter((i) => i !== variableSetup.rowIndex)
    );
    setVariableSetup({
      rowIndex: null,
      firstMonthAmount: "",
      inputType: null,
      adjustment: "",
    });
  };

  return (
    <div className="w-full text-white max-w-full px-4">
      <style>
        {`
          /* For Chrome, Safari, Edge */
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* For Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        `}
      </style>

      {step === 1 && (
        <>
          <p className="text-white self-start text-3xl md:text-5xl lg:text-5xl mt-8 mb-4">
            <FontAwesomeIcon icon={faMoneyBill} className="mr-4" />
            Expected Costs
          </p>
          <p className="text-gray-200 self-start text-xl">
            How much do you expect to spend, where, and when?
          </p>

          <div className="flex gap-2 justify-end">
            <div className="flex items-center space-x-4">
              <button
                className="text-gray-800 bg-gray-200 border rounded-lg p-2 mb-2 text-sm "
                onClick={() => setShowAdvanced(!showAdvanced)}
              >
                <FontAwesomeIcon className="mr-2" icon={faGears} />
                {showAdvanced ? "Hide Advanced Settings" : "Advanced Settings"}
              </button>
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="text-gray-800 bg-gray-200 border rounded-lg p-2 mb-2 text-sm "
                onClick={() => setShowInfo(!showInfo)}
              >
                <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
                {showInfo ? "Hide More Info" : "More Info"}
              </button>
            </div>
          </div>

          {showAdvanced && (
            <div className="flex flex-col gap-2 mb-4">
              <div className="flex items-center space-x-4">
                <label className="flex items-center space-x-2 text-white text-sm">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4"
                    checked={excludeVAT}
                    onChange={(e) => setExcludeVAT(e.target.checked)}
                  />
                  <span>Exclude VAT from amounts</span>
                </label>
              </div>
              <div className="flex items-center space-x-4">
                <label className="flex items-center space-x-2 text-white text-sm">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4"
                    checked={showSubcategoryColumn}
                    onChange={(e) => setShowSubcategoryColumn(e.target.checked)}
                  />
                  <span>Include subcategory column</span>
                </label>
              </div>
            </div>
          )}

          {showInfo && (
            <div className="bg-blue-900 text-left p-4 rounded-lg mt-2">
              <p className="text-white text-sm mb-2">
                When do you expect to make payment? Enter the number of days
                after the month ends (e.g. 30 means end of next month)
              </p>
              <p className="text-white text-sm">
                {excludeVAT
                  ? "Enter amounts excluding VAT. VAT will be calculated automatically for VATable items."
                  : "Enter amounts including VAT. Net amounts will be calculated automatically for VATable items."}
              </p>
            </div>
          )}

          <CostsTable
            costs={costs}
            costsNeedingType={costsNeedingType}
            availableCategories={availableCategories}
            onInputChange={handleInputChange}
            onAmountChange={handleAmountChange}
            onDeleteCost={handleDeleteCost}
            onCostTypeSelect={handleCostTypeSelect}
            onCopyPreviousMonth={handleCopyPreviousMonth}
          />

          <div className="flex flex-col sm:flex-row justify-between mb-4 space-y-2 sm:space-y-0">
            <button
              className="bg-blue-500 text-white px-4 py-2 text-sm rounded-lg"
              onClick={handleAddCost}
            >
              <FontAwesomeIcon className="mr-2" icon={faAdd} /> Add Cost
            </button>
            <div className="flex space-x-2">
              <button
                className="bg-gray-500 text-white p-2 text-sm rounded-lg hover:bg-gray-600 transition-colors w-fit h-10 flex items-center justify-center"
                onClick={onBack}
                title="Back"
              >
                <FontAwesomeIcon className="mr-2" icon={faChevronLeft} /> Back
              </button>
              <button
                className="bg-yellow-500 text-white p-2 text-sm rounded-lg hover:bg-green-600 transition-colors w-10 h-10 flex items-center justify-center"
                onClick={handleSave}
                title="Save"
              >
                <FontAwesomeIcon icon={faSave} />
              </button>
              <button
                className="bg-green-500 text-white p-2 text-sm rounded-lg hover:bg-yellow-600 transition-colors w-fit font-bold h-10 flex items-center justify-center"
                onClick={handleNext}
                title="Next"
              >
                Save & Continue
                <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
              </button>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <BankForecast documentId={documentId} onBack={() => setStep(1)} />
      )}

      {showVariableModal && (
        <VariableCostModal
          showModal={showVariableModal}
          variableSetup={variableSetup}
          setVariableSetup={setVariableSetup}
          onClose={() => setShowVariableModal(false)}
          onSave={handleVariableSetup}
        />
      )}
    </div>
  );
}

export default Costs;
